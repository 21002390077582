import { IPostKPIs } from '@/interfaces/derivedKpis';
import client from '../apiService';

class DerivedKpisService{
    async GetKpis(searchTerm: string,page: number){
        const url = `DerivedKPI/GetDerivedKPIs?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }
    updateKpi(kpi: IPostKPIs){
        const url = `DerivedKPI/CreateUpdateDerivedKPI`;
        return client.post(url, kpi, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    async getInactiveKpis(searchTerm: string,page:number){
        const url = `DerivedKPI/GetInactiveDerivedKPIs?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    createKpi(kpi: IPostKPIs){
        const url = `DerivedKPI/CreateUpdateDerivedKPI`;
        return client.post(url, kpi, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    activateKpi(kpiId: number){
        const url = `DerivedKPI/ActivateKPI?kpiId=${kpiId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    deactivateKpi(kpiId: number){
        const url = `DerivedKPI/DeactivateKPI?kpiId=${kpiId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    async GetKpiRestrictions(){
        const url = `DerivedKPI/GetDerivedKPIRestriction`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
  async getInputMeasure(){
            const url = `DerivedKPI/GetDerivedKPIInputMeasures`;
            var result =  await client.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return result;
}
}

export default new DerivedKpisService();

