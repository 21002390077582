






































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Navbar from '@/components/common/Navbar.vue';
import DataTable from '@/components/common/Tables/DataTable.vue';
import TextButton from '@/components/common/Buttons/TextButton.vue';
import {ILink, ITableHeader} from '@/interfaces/common';
import SideDrawer from '@/components/common/SideDrawer.vue';
import Snackbar from '@/components/common/Snackbar.vue';
import DropDown from '@/components/common/Inputs/DropDown.vue';
import ListItem from '@/components/common/List/ListItem.vue';
import ListItemContent from '@/components/common/List/ListItemContent.vue';
import Illustration from '@/components/common/Illustration.vue';
import {IInputMeasures, IKPIs, IPostKPIs} from '@/interfaces/derivedKpis';
import DerivedKpisService from '@/services/DerivedKpisService';
import { VLayout } from 'vuetify/lib';
import ActionablePopup from '@/components/common/Popup/ActionablePopup.vue'
import SearchBar from "@/components/common/SearchBar.vue";
import Popup from '@/components/common/Popup/Popup.vue'

@Component({
  components: {
    SearchBar,
    Navbar,
    DataTable,
    TextButton,
    Illustration,
    SideDrawer,
    Snackbar,
    DropDown,
    ListItem,
    ListItemContent,
    ActionablePopup,
    Popup,
  }
})
export default class Create extends Vue {
  [x: string]: any;
  private restrictionPopup: boolean = false;
  private description: string = "No Measures Found"
  private subdescription: string = "Please create a Measure to begin with"
  private showKpiList: boolean = true
  private KpiLoading: boolean = false
  private createKpi: boolean = true
  private derivedMeasureSearch: string = ""
  private sidedrawerTitle: string = "Add Derived Measures"
  private sideDrawer: boolean = false
  private timeout !: NodeJS.Timeout
  private valid: boolean = false
  private message: string = '';
  private snackbar: boolean = false
  private showTextField: number= 0
  private snackbarColor: string = "success"
  private menuItems: Array<ILink> = []
  private kpi: Array<IKPIs> = []
  private selectedKpiId: number = 0
  private totalKpi: number = 0
  private pages: number = 0
  private page: number = 1
  private kpiSearch: string = ""
  private inactiveKpis: boolean = false
  private selectedActionMenu: number = -1
  private tableHeaders: Array<ITableHeader> = [
    {
      text: "Measure Names",
      value: "derivedMeasureName",
      sortable: true,
      default: ''
    },
    {
      text: "Perspective",
      value: "perspective",
      sortable: false,
      default: ''
    },
    {
      text: "Input Measures 1",
      value: "inputMeasure1Name",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Operation",
      value: "operation",
      sortable: false,
      default: ''
    },
    {
      text: "Input Measures 2",
      value: "inputMeasure2Name",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
  ]
  private inputmeasures: Array<IInputMeasures> =[]
  private perspectives = [
          { key: 1, value: "User Performance" },
          { key: 2, value: "Product Performance" },
          { key: 3, value: "Outlet Performance" },
          { key: 4, value: "L3M User performance" },
          { key: 5, value: "Day Summary"},
          { key: 10, value: "MOM User performance" },
          { key: 18, value: "Outlet wise Demand vs Sales" },
          { key: 19, value: "Product wise Demand vs Sales" },
          { key: 20, value: "Distributor Performance" },
          { key: 21, value: "Distributor Product Performance" },
          { key: 50, value: "Out of Stock" },
          { key: 51, value: "Shelf Share" },
        ];
   private inputmeasures1: Array<IInputMeasures> =[...this.inputmeasures]
   private inputmeasures2: Array<IInputMeasures> =[...this.inputmeasures]
   private selectedIndex1: number= -1
   private selectedIndex2: number= -1
   private canCreateMore: boolean = false
   private createRestrictionMessage: string = "Maximum 5 Derived Measures are allowed in the Basic plan. Please Upgrade/Contact your Account Manager"
   private previousOperations: number= 0
   private errorStatus:number=0
   private presentOperations: number= 0
   private confirmationPopup: boolean = false
   private showIM2: boolean = true
   private isNome: boolean = false
        private confirmationPopupTitle: string = ""
        private confirmationPopupSubtitle: string = ""
        private confirmationPopupText: string = ""
        private cancelBtnText: string = ""
        private confirmBtnText: string = ""
   public editedItem: IKPIs  = {
    derivedMeasureName: '', 
    perspective: {
      key: 0,
      value: ''
    },
     inputMeasure1Name:'',
    inputMeasure2Name: '', 
    inputMeasure1:
    { 
      id:0,
    inputMeasureKey:'',
    inputMeasureDisplayName:'',
    perspective:0
    },
    id: 0,
    inputMeasure2:  {
    id:0,
    inputMeasureKey:'',
    inputMeasureDisplayName:'',
    perspective:0
    },
    operation:'',
    operationId:0

  }
  private defaultItem: IKPIs = {
    derivedMeasureName: '', 
     perspective: {
      key: 0,
      value: ''
    },
    inputMeasure1: { 
      id:0,
    inputMeasureKey:'',
    inputMeasureDisplayName:'',
    perspective:0
    },
    inputMeasure1Name:'',
    inputMeasure2Name: '',
    id: 0,
    inputMeasure2: { 
      id:0,
    inputMeasureKey:'',
    inputMeasureDisplayName:'',
    perspective:0
    },
    operation:'',
    operationId:0
  }
  
  private creatingOrUpdatingKpi: boolean = false;


  get formValidate(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }
  get formResetValidation(): Vue & { resetValidation: () => void } {
    return this.$refs.form as Vue & { resetValidation: () => void };
  }
    @Watch('derivedMeasureSearch')
        searchVan(newVal: string){
            if(newVal && newVal.length > 2){
                this.timeout = setTimeout(() => {
                    this.inactiveKpis ? this.getInactiveKpis(newVal,1) :  this.getKpi(newVal,1);
                }, 1000);
            }else if(newVal.length == 0){
                this.timeout = setTimeout(() => {
                    this.inactiveKpis ? this.getInactiveKpis("",1) : this.getKpi("",1)
                }, 1000);
            }
        }
public changeInputMeasure2()
{
  if(this.editedItem.inputMeasure1 != undefined && this.editedItem.inputMeasure1.inputMeasureKey == 'Numeric' || this.editedItem.inputMeasure1.toString() == 'Numeric'){
    this.showIM2 = false;
  }
  else{
    this.showIM2 = true;
  }
this.inputmeasures2=this.getFilteredInputMeasures();
var index = this.inputmeasures2.findIndex(x => x.inputMeasureKey == 'Numeric');
if(index>-1){  this.inputmeasures2.splice(index,1);}
this.selectedIndex1=this.getFilteredInputMeasures().indexOf(this.editedItem.inputMeasure1);
this.$delete(this.inputmeasures2,this.selectedIndex1);
}  
public changeMeasureAccToPerspective(){
   this.inputmeasures1=Array<IInputMeasures>();
     this.inputmeasures2=Array<IInputMeasures>();
  this.inputmeasures1 = this.getFilteredInputMeasures();
this.inputmeasures2 = this.getFilteredInputMeasures();
var index = this.inputmeasures2.findIndex(x => x.inputMeasureKey == 'Numeric');
if(index>-1){    this.inputmeasures2.splice(index,1);}
}
public getFilteredInputMeasures(){
  var list = this.inputmeasures.filter((elem) =>{
    if(elem.perspective == this.editedItem.perspective.key) return elem;
});
return list;
}
public changeInputMeasure1()
{
this.inputmeasures1=this.getFilteredInputMeasures();
this.selectedIndex2=this.getFilteredInputMeasures().indexOf(this.editedItem.inputMeasure2);
this.$delete(this.inputmeasures1,this.selectedIndex2);
}  
private getOperations(id : number)
   {
  this.presentOperations=id;
   if(this.previousOperations==0 || this.presentOperations!=this.previousOperations)
   {
     this.previousOperations=id;
  }
  else if(this.previousOperations!=0 && this.presentOperations==this.previousOperations)
  {
    this.presentOperations=0;
    this.previousOperations=0;
  }
  } 
  public updatedValue(updatedValue: string)
    {
      if(updatedValue=="Add")
        return 1;
      else if (updatedValue=="Subtract")
        return 2;
      else if (updatedValue=="Multiply")
        return 3;
      else if (updatedValue=="Divide")
        return 4;
      else if (updatedValue=="Percentage")
        return 5;
      else if (updatedValue=="Nomenclature")
        return 6;
      else
      return 0;
 }
public creatNewKPI(){
  if(this.canCreateMore){
    this.createKpi = true;
    this.openDrawer('');
  }
  else{
    this.createKpi = false;
    this.restrictionPopup = true;
  }
}
public showCommunicationError(err : any) {    
            this.snackbarColor = "red";
            this.snackbar = true;
            this.message = window.navigator.onLine ? `Some Error occured` : `Cannot reach server at the moment`;
}
public async refreshKpiList(){
     this.inactiveKpis ? await this.getInactiveKpis(this.derivedMeasureSearch,1) : await this.getKpi(this.derivedMeasureSearch,1);
  }
  public actionablePopup(){
            this.snackbar = true;
            this.confirmationPopup = false;
            this.showDiscountList = true;
        } 
      private getRefAsAny(refKey: string){
            return (this.$refs[refKey] as any)
        }
    
  public editKpi(value: IKPIs): void {
    this.createKpi = false;
    this.openDrawer('');

    var valObj = this.inputmeasures.filter(function(elem){
    if(elem.inputMeasureKey.toString() == value.inputMeasure1.toString()) return elem.inputMeasureKey;
    });
    var indexForNumeric = this.inputmeasures.findIndex(x=>x.inputMeasureKey == 'Numeric');
    var indexForPerspectiveSelected = this.perspectives.findIndex(x=>x.value == value.perspective.toString());
    this.showTextField= valObj.length>0 && valObj[0].inputMeasureKey!='Numeric' ? 0 : parseInt(value.inputMeasure1.toString());
    this.editedItem = Object.assign({},
      {
        derivedMeasureName: value.derivedMeasureName,
        perspective: {
          key: this.perspectives[indexForPerspectiveSelected].key,
          value: this.perspectives[indexForPerspectiveSelected].value
        },
        inputMeasure1: valObj.length>0 && valObj[0].inputMeasureKey!='Numeric' ? value.inputMeasure1 : this.inputmeasures[indexForNumeric],
        inputMeasure2: value.inputMeasure2,
        id: value.id,
        operation:value.operation,
        operationId:value.operationId,
         inputMeasure1Name:'',
        inputMeasure2Name: '',
      })
      this.inputmeasures1 = this.getFilteredInputMeasures();
      this.inputmeasures2 = this.getFilteredInputMeasures();
      this.changeInputMeasure2();
      }
  public updateKpi(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrUpdatingKpi = true;
    DerivedKpisService.updateKpi({
      derivedMeasureName: value.derivedMeasureName,
        perspective: value.perspective.key.toString(),
        inputMeasure1: value.inputMeasure1.inputMeasureKey!= undefined && value.inputMeasure1.inputMeasureKey == "Numeric" || value.inputMeasure1.toString() == "Numeric" ? this.showTextField.toString() :value.inputMeasure1.inputMeasureKey!=undefined ?value.inputMeasure1.inputMeasureKey : value.inputMeasure1.toString(),
        inputMeasure2: value.inputMeasure1.inputMeasureKey!= undefined && value.inputMeasure1.inputMeasureKey == "Numeric" || value.inputMeasure1.toString() == "Numeric" ? "": value.inputMeasure2.inputMeasureKey!=undefined ? value.inputMeasure2.inputMeasureKey : value.inputMeasure2.toString(),
        id: value.id,
        operation: value.inputMeasure1.inputMeasureKey!= undefined && value.inputMeasure1.inputMeasureKey == "Numeric" || value.inputMeasure1.toString() == "Numeric" ? 0: (this.presentOperations==0 ? this.updatedValue(value.operation) : this.presentOperations),
        operationId:value.operationId,
        })
      .then((response) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showKpiList = true;
        this.creatingOrUpdatingKpi = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshKpiList();
        this.presentOperations=0;
        this.previousOperations=0;
        })
      .catch((error) => {
        this.creatingOrUpdatingKpi = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      })
    }
    public activateKpi(kpi: IKPIs){
      if(this.canCreateMore){
            this.selectedKpiId = kpi.id;
            this.confirmationPopupTitle = "Confirm Activation";
            this.confirmationPopupSubtitle = kpi.derivedMeasureName+" Measure will be activated.";
            this.confirmationPopupText = "";
            this.cancelBtnText = "Keep Inactive";
            this.confirmBtnText = "Activate";
            this.confirmationPopup = true;
      }
      else{
        this.restrictionPopup = true;
      }
    }
        public menuAction(kpi: IKPIs, commandIndex: number){
            this.selectedKpiId = kpi.id;
            this.selectedActionMenu = commandIndex;
            switch (this.menuItems[commandIndex].Text) {
                case "DEACTIVATE": 
                        this.confirmationPopupTitle = "Confirm Deactivation";
                        this.confirmationPopupSubtitle = "Are you sure you wish to deactivate the " + kpi.derivedMeasureName + " Measure?";
                        this.confirmationPopupText = "";
                        this.cancelBtnText = "Cancel";
                        this.confirmBtnText = "Confirm";
                        this.confirmationPopup = true;
                    break;
                default: console.log("Unhandled menuAction")
            }
        }
  public checkInput()
  {
    let value = Object.assign({}, this.editedItem);
    let kp = 0;
    this.kpi.filter(function(elem){
    if(elem.derivedMeasureName == value.derivedMeasureName && (elem.perspective && elem.perspective.toString() == value.perspective.value))
    {
      kp = 1;
      return;
    }
   });
if(kp!=1)
{
  if(  value.inputMeasure1.inputMeasureKey != undefined && value.inputMeasure1.inputMeasureKey.toString().length ==0) 
      {
        this.snackbarColor ="red";
        this.snackbar = true;
        this.message =` Please select Input measure 1`;
        return;
      }
 if((this.presentOperations == 0 &&  value.inputMeasure2.inputMeasureKey =="") && (value.inputMeasure1.inputMeasureKey != "Numeric"))
      {
        this.snackbarColor ="red";
        this.snackbar = true;
        this.message =` Please select Input measure 2 and Operation`;
      }
    else if((this.presentOperations != 0 && value.inputMeasure2.inputMeasureKey !="") || (value.inputMeasure1.inputMeasureKey == "Numeric"))
      {
            this.createNewKpi();
      }
    else if(this.presentOperations == 0 && ( value.inputMeasure2.inputMeasureKey !=null || value.inputMeasure2.inputMeasureKey !="" && value.operation.length ==0))
      {     
            this.snackbarColor = "red";
            this.snackbar = true;
            this.message =` Please select an operation to be performed`;
       }
      else if(this.presentOperations!=0 && this.presentOperations!=6 && (value.inputMeasure2.inputMeasureKey !=null || value.inputMeasure2.inputMeasureKey !=""))
      {
         this.snackbarColor = "red";
         this.snackbar = true;
         this.message =` Please select Input measure 2 for the operation to be performed`;
      }
      else{
                this.createNewKpi();
                }
}
else{
   this.snackbarColor = "red";
   this.snackbar = true;
   this.message =`KPI with the same name is already existing`;
}
}

  public checkInputOnEdit()
  { 
     this.editedItem = this.editedItem;
     let value = Object.assign({}, this.editedItem);
      if(this.kpi.some(k => k.id != this.editedItem.id && k.derivedMeasureName == this.editedItem.derivedMeasureName && (k.perspective && k.perspective.toString() == this.editedItem.perspective.value))){
        this.snackbarColor ="red";
        this.snackbar = true;
        this.message =`KPI with the same name is already existing`;
      }
      else if( value.inputMeasure1.inputMeasureKey != undefined && value.inputMeasure1.inputMeasureKey.toString().length ==0) 
      {
        this.snackbarColor ="red";
        this.snackbar = true;
        this.message =`Please select Input measure 1`;
      }
      else if((this.presentOperations == 0 &&  value.inputMeasure2.toString() =="") && (value.inputMeasure1.toString()!= "Numeric" && value.inputMeasure1.inputMeasureKey!="Numeric"))
      {
        this.snackbarColor ="red";
        this.snackbar = true;
        this.message =`Please select Input measure 2 and Operation`;
      }
      else if(((this.presentOperations!= 0 && value.inputMeasure2!=null || "") && value.operation!=undefined) || (value.inputMeasure1.toString() == "Numeric"))
      {
            this.updateKpi();
      }
      else if((this.presentOperations == 0 || value.operation==undefined) && ( value.inputMeasure2 !=null && value.inputMeasure2.toString() !="" && value.operation==null ))
      {     
            this.snackbarColor = "red";
            this.snackbar = true;
            this.message =`Please select an operation to be performed`;
      }
       else if(this.presentOperations!=0 && (value.inputMeasure2!=null || value.inputMeasure2!=""))
      {
         this.snackbarColor = "red";
         this.snackbar = true;
         this.message =`Please select Input measure 2 for the operation to be performed`;
      }
      else
      {
            this.updateKpi();
      }
  }
  public createNewKpi(): void {
  let value = Object.assign({}, this.editedItem);
  this.creatingOrUpdatingKpi = true;
    DerivedKpisService.createKpi({
      derivedMeasureName: value.derivedMeasureName,
        perspective: value.perspective.key.toString(),
        inputMeasure1: value.inputMeasure1.inputMeasureKey == "Numeric" ? this.showTextField.toString() :value.inputMeasure1.inputMeasureKey, 
        inputMeasure2: value.inputMeasure1.inputMeasureKey == "Numeric" ? "": value.inputMeasure2.inputMeasureKey,
        id: value.id,
        operation:value.inputMeasure1.inputMeasureKey == "Numeric" ? 0: this.presentOperations,
        operationId:value.operationId,
      })
      .then((response) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showKpiList = true;
        this.creatingOrUpdatingKpi = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshKpiList();
        this.getKPIRestrictions();
        })
      .catch((error) => {
        this.creatingOrUpdatingKpi = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      })
  }
  public async getKpi(searchTerm: string,page:number): Promise<boolean>{
            this.KpiLoading = true;
            return new Promise<boolean>(res => {
                DerivedKpisService.GetKpis(this.derivedMeasureSearch,page).then((response) => {
                    this.kpi = response.data.kpis;
                    this.pages = response.data.pages;
                    this.totalKpi = response.data.total;
                    this.kpi.forEach(element => {
                     var element1 = this.inputmeasures.find(x=> x.inputMeasureKey.toString() == element.inputMeasure1.toString());
                     var element2 = this.inputmeasures.find(x=> x.inputMeasureKey.toString() == element.inputMeasure2.toString());
                   element.inputMeasure1Name = element1 != undefined ? element1?.inputMeasureDisplayName : element.inputMeasure1.toString(),
                    element.inputMeasure2Name = element2 != undefined ? element2?.inputMeasureDisplayName : element.inputMeasure2.toString()
                   });
                    this.KpiLoading = false;
                    if(this.kpi.length > 0){
                        this.showKpiList = true;
                    }else{
                        this.showKpiList = false;
                        this.description = this.kpi.length == 0 ? "No Measures Found" : "" ;
                        this.subdescription = this.kpi.length == 0 ? "Please create a Measure to begin with" : "";
                    }
                    res(true);
                }).catch((error) : any => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.KpiLoading = false;
                })
            })
        }

 public async getInputMeasure(): Promise<boolean>{
            this.KpiLoading = true;
             return new Promise<boolean>(res => {
                DerivedKpisService.getInputMeasure().then((response) => {
                    this.inputmeasures = response.data.inputmeasures;
                    this.KpiLoading = false;
                    res(true);
                }).catch((error) : any => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.KpiLoading = false;
                })
            })
        }     
  public changePage(value: number): void {
     this.page = value;
     this.inactiveKpis ? this.getInactiveKpis(this.derivedMeasureSearch,value) : this.getKpi(this.derivedMeasureSearch,value);
  }
  public confirmAction(value: any):void{
  if(this.inactiveKpis){
  DerivedKpisService.activateKpi(value).then((res) => {
  this.snackbarColor = "success";
  this.message = "Changes Saved Successfully";
  this.actionablePopup();
  this.switchKpis();
  }).catch((err: any) => {
  this.showCommunicationError(err);
  console.log(err);
 })
  }
            else{
                DerivedKpisService.deactivateKpi(value).then((res) => {
                    this.snackbarColor = "red";
                    this.message = "Measure deactivated and moved to inactive list";
                    this.actionablePopup();
                    this.refreshKpiList();
                }).catch((err) => {
                    this.showCommunicationError(err);
                    console.log(err);
                })
            }
        } 
  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
    }
  private createOrUpdateKpi(): void {
    if (!this.creatingOrUpdatingKpi)
      this.createKpi ? this.checkInput() : this.checkInputOnEdit();
     }
   public openDrawer(value: string): void {
    this.sidedrawerTitle = this.createKpi
      ? "Fill Derived Measure Details"
      : "Edit Derived Measure Details";
    this.clearForm();
    this.sideDrawer = true;
    this.showTextField = 0;
    this.showIM2 = true;
    this.formResetValidation.resetValidation();
     this.inputmeasures1=Array<IInputMeasures>();
     this.inputmeasures2=Array<IInputMeasures>();
    this.operation;
    this.kpi;
 }
public closeSideDrawer(value: boolean): void {
this.hideWindowScrollBar(value);
this.sideDrawer = value;
}
private async GetMenuActions(): Promise<void> {
this.menuItems = [
{
Text: "DEACTIVATE",
Color: "red"
}];
}
private async getKPIRestrictions(): Promise<void> {
  DerivedKpisService.GetKpiRestrictions().then( response => {
    this.canCreateMore = response.data.canCreateMoreKPIs;
    this.createRestrictionMessage = response.data.errorMessage;
  }).catch((error) => {
    this.showCommunicationError(error);
    console.log(error);
    this.KpiLoading = false;
  });
}
public async getInactiveKpis(serachTerm: string,page:number): Promise<boolean>{
            this.KpiLoading = true;
            return new Promise<boolean>(res => {
                DerivedKpisService.getInactiveKpis(this.derivedMeasureSearch,page).then((response) => {
                    this.kpi = response.data.kpis;
                    this.pages = response.data.pages;
                    this.totalKpi = response.data.total;
                    this.KpiLoading = false;
                     this.kpi.forEach(element => {
                     var element1 = this.inputmeasures.find(x=> x.inputMeasureKey.toString() == element.inputMeasure1.toString());
                     var element2 = this.inputmeasures.find(x=> x.inputMeasureKey.toString() == element.inputMeasure2.toString());
                   element.inputMeasure1Name = element1 != undefined ? element1?.inputMeasureDisplayName : element.inputMeasure1.toString(),
                    element.inputMeasure2Name = element2 != undefined ? element2?.inputMeasureDisplayName : element.inputMeasure2.toString()
                   });
                    this.KpiLoading = false;
                    if(this.kpi.length > 0){
                        this.showKpiList = true;
                    }else{
                        this.showKpiList = false;
                        this.description = this.kpi.length == 0 ? "No Measures Found" : "" ;
                        this.subdescription = this.kpi.length == 0 ? "Please create a measure to begin with" : "";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.KpiLoading = false;
                })
            })
        }
  private hideWindowScrollBar(hideIt: boolean) {
  window.document.documentElement.style.overflowY = hideIt
      ? "hidden"
      : "scroll";
  }
  public clearForm(): void {
  this.editedItem = Object.assign({}, this.defaultItem);
  }
   public switchKpis(){
     if(this.inactiveKpis){
     this.tableHeaders = [
     {
      text: "Derived Measures Name",
      value: "derivedMeasureName",
      sortable: true,
      default: ''
    },
    {
      text: "Perspective",
      value: "perspective",
      sortable: false,
      default: ''
    },
    {
      text: "Input Measures 1",
      value: "inputMeasure1Name",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Operation",
      value: "operation",
      sortable: false,
      default: ''
    },
    {
      text: "Input Measures 2",
      value: "inputMeasure2Name",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
    ];
    this.getInactiveKpis(this.derivedMeasureSearch,1);
    }
    else{
    this.tableHeaders = [
      {
      text: "Derived Measures Name",
      value: "derivedMeasureName",
      sortable: true,
      default: ''
    },
    {
      text: "Perspective",
      value: "perspective",
      sortable: false,
      default: ''
    },
    {
      text: "Input Measures 1",
      value: "inputMeasure1Name",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Operation",
      value: "operation",
      sortable: false,
      default: ''
    },
    {
      text: "Input Measures 2",
      value: "inputMeasure2Name",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
    ];
    this.getKpi(this.derivedMeasureSearch,1);
    }
  }
  async created(){
  this.KpiLoading = true;
  this.getKPIRestrictions()
  this.getKpi(this.derivedMeasureSearch,1);
  this.getInputMeasure();
  this.GetMenuActions().then(_ => _);
  }
}
